import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader } from "@nextui-org/react";
import { Product } from "../WizefindClient";
import { Plus, X } from "lucide-react";

const ProductCardSmall = ({
  product,
  className = "",
  onProductButtonClick,
  selectedProducts,
}: {
  product: Product;
  className?: string;
  imgMaxWidth?: string;
  imgMaxHeight?: string;
  onProductButtonClick: (product: Product) => void;
  selectedProducts: Product[];
}) => {
  const videoCount = product.mentioned_in_youtube_videos?.length || 0;
  const formattedPrice = product.google_shopping_data?.price
    ? product.google_shopping_data.price.split(".")[0]
    : null;

  const isSelected = selectedProducts.some(p => p.name === product.name);
  const canAddMore = selectedProducts.length < 3;

  return (
    <Card 
    className={`w-full ${className}`}
    shadow="none"
    radius="none"
  >
    <CardHeader className="flex flex-col pt-2">
      <p className="text-center mx-auto text-xs text-gray-500">
        Recommended in <span className="text-blue-500">{videoCount}</span>{" "} videos
      </p>
    </CardHeader>
    <CardBody className="flex items-center justify-center p-1">
      {product.google_shopping_data?.thumbnail ? (
        <img
          src={product.google_shopping_data.thumbnail}
          alt=""
          className="object-fit max-w-20 max-h-20 mx-auto"
        />
      ) : (
        <div className="bg-gray-50 w-20 h-20 mx-auto" />
      )}
    </CardBody>
    <CardFooter className="flex flex-col pb-3">
      <div className="flex-grow">
        <p className="font-semibold text-sm text-center line-clamp-2 mb-1">
          {product.name}
        </p>
        <p className="text-gray-500 text-sm font-mono text-center">
          {formattedPrice}
        </p>
      </div>
      <Button
        className="w-full mt-2"
        color={isSelected ? "danger" : "primary"}
        radius="none"
        size="sm"
        variant="flat"
        onClick={() => onProductButtonClick(product)}
        disabled={!canAddMore && !isSelected}
      >
        {isSelected ? "Remove" : canAddMore ? "Add to Compare" : "Remove"}
      </Button>
    </CardFooter>
  </Card>
  );
};

export default ProductCardSmall;