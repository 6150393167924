import React from 'react';
import ProductCardSmall from './ProductCardSmall';
import { Product } from '../WizefindClient';


interface DiscoveredProductsGalleryProps {
  discoveredProducts: Product[];
  selectedProducts: Product[];
  onProductButtonClick: (product: Product) => void;
}

export const DiscoveredProductsGallery: React.FC<DiscoveredProductsGalleryProps> = ({
  discoveredProducts,
  onProductButtonClick,
  selectedProducts,
}) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-4 bg-gray-100 py-6 px-3">
      {discoveredProducts.map((product: Product, productIndex: number) => (
        <ProductCardSmall
          key={productIndex}
          product={product}
          onProductButtonClick={onProductButtonClick}
          selectedProducts={selectedProducts}
        />
      ))}
    </div>
  );
};

export default DiscoveredProductsGallery;