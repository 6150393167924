import { useState } from "react";
import Navbar from "./components/Navbar";
import { productCategoryCardData } from './productCategoryCardData';

import SearchBox from "./components/SearchBox";
import ProductCategoryCard from "./components/ProductCategoryCard";

export enum SearchType {
  COMPARE_PRODUCTS,
  FIND_BEST_PRODUCT,
  FIND_ALTERNATIVES,
}


export interface VideoData {
  video_id: string;
  video_title: string;
  length: string;
  video_thumbnail: string;
  transcript: string;
  link: string;
}

const MainPage = () => {
  const [userQuery, setUserQuery] = useState<string>("")
  const [searchType, setSearchType] = useState(SearchType.FIND_BEST_PRODUCT);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <Navbar></Navbar>

      {/* <VerticalStepper/> */}

      <div className="py-2 sm:py-4"></div>

      {/* Header Text */}
      <div className="max-w-3xl px-4 mx-auto">
        <h1 className="text-2xl sm:text-3xl italic">
          Don't know what to buy?
        </h1>
        {/* <h1 className="text-3xl sm:text-5xl font-extrabold mt-2">
          Can't decide?
        </h1> */}
        {/* <h1 className="text-3xl sm:text-5xl font-extrabold mt-2">
        Overwhelmed deciding between too many products?
        </h1> */}
        {/* <h1 className="text-3xl sm:text-5xl font-extrabold mt-2">
        Overwhelmed by options?
        </h1> */}
        {/* <h1 className="text-3xl sm:text-5xl font-extrabold">
          We save you from the next Rabbit Hole.
        </h1> */}
        {/* <h1 className="text-3xl sm:text-5xl font-extrabold">
          Watched hours of YouTube Reviews and still confused?
        </h1> */}
        <h2 className="text-4xl mt-2 font-extrabold">
          {/* We save you from the next Rabbit Hole. */}
          Skip the YouTube rabbit hole.
        </h2>
        <h1 className="text-2xl mt-4">
          AI-based discovery and comparison of any product.

          {/* Our AI {" "}
          sifts through YouTube to {" "}
          <span className="font-bold">discover the most recommended products</span> and
          <span className="font-bold">{" "}summarizes all {" "}</span>
          <span className="font-bold underline decoration-4 decoration-green-500/50">pros</span>{" "}
          &{" "}
          <span className="font-bold underline decoration-4 decoration-red-500/50">
            cons
          </span>{" "}
          for you. */}
        </h1>

      </div>

      <div className="py-3 sm:py-3"></div>

      <SearchBox
        isLoading={isLoading}
        searchType={searchType}
        searchInput={userQuery}
        setSearchInput={setUserQuery}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      ></SearchBox>

      <div className="py-6"></div>

      <div className="bg-gray-100 py-6">
        <div className="max-w-5xl px-3 mx-auto grid grid-cols-2 md:grid-cols-4 gap-6">
          {productCategoryCardData.map((product, index) => (
            <ProductCategoryCard
              key={index}
              id={product.id}
              title={product.title}
              w={200}
              h={200}
            />
          ))}
        </div>
      </div>

      <div className="py-18"></div>
    </div>
  );
};

export default MainPage;
