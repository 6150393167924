import React from 'react';

interface DisplayContentProps {
    when: boolean;
    children: React.ReactNode;
}

const DisplayContent: React.FC<DisplayContentProps> = ({ when: condition, children }) => {
    return condition ? <>{children}</> : null;
};

export default DisplayContent;