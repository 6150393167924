import { Skeleton } from "@nextui-org/skeleton";

const ProductComparisonTableSkeleton = () => {
    return (
        <div className="max-w-6xl mx-auto">
            <div className="py-4"></div>
            <table className="w-full max-w-6xl mx-auto">
                <thead>
                    <tr className="border-b-2">
                        {Array.from({ length: 4 }).map((_, index: number) => (
                            <th
                                key={index}
                                className="p-2 font-bold text-lg"
                                style={{ width: `${100 / 4}%` }}
                            >
                                <Skeleton className="max-w-56 h-5 rounded-lg mx-auto" />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...Array(6)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {Array.from({ length: 4 }).map((_, colIndex) => (
                                <td
                                    key={colIndex}
                                    className="p-2"
                                    style={{ width: `${100 / 4}%` }}
                                >
                                    <Skeleton className="w-full h-5 rounded-lg" />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductComparisonTableSkeleton; 