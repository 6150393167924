import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface ConclusionSectionProps {
    conclusion: string | null;
}

const ConclusionSection = ({ conclusion }: ConclusionSectionProps) => {
    return (
        <div
            className={`max-w-3xl px-3 mx-auto transition-opacity duration-1000 ease-in-out ${
                conclusion ? "opacity-100" : "opacity-0"
            }`}
        >
            <div className="p-2 bg-black text-white rounded-md mb-4">
                <h1 className="text-3xl font-extrabold text-center">Who should buy what?</h1>
            </div>

            <div className="prose max-w-full leading-5">
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        ul: ({ node, ...props }) => <ul className="list-disc list" {...props} />,
                    }}
                >
                    {conclusion}
                </ReactMarkdown>
            </div>
        </div>
    );
};

export default ConclusionSection; 