export const productCategoryCardData = [
  { 
    id: "4f33a966-5a91-48bf-bd62-ad6162b94d1b",
    imageUrl: "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcTN1w2_qftXqMZBKGna84TPbt7_ixz_okvkxRg2ajlmK0Rt5Sn5MQ0UX13_gzO10K2aK7yxzURCzywkVHa4In1TG5uf1EMp8SESEYHmUlQ&usqp=CAE",
    title: "Earbuds",
  },
  {
    id: "5681173a-172f-4b35-9f17-60bbb41f9eb6",
    imageUrl: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcQdNaIk5bX-mEXBwz8pOeyrvNEaLAv4pWneGQAEXkQExXNzu3FgPv1UzMCTAGlpovajwDeQjTOnZbRPJwmIlLkdQC88BiFLbHiiDx54_fyvyN4HIX6GvwTZnQ&usqp=CAE",
    title: "Headphones",
  },
  {
    id: "db6793e6-1d48-4028-910d-b1fc6067698f",
    imageUrl: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcS9KcT2WcLuWG6F1hbOEqMiJJLPSYljdwxFfG0IajyGLuIvfspI3QcidQCUXK3a39A6beP9coDXH64Gn2cuHW50A_NF7pjMJVbT7x32sFyxTiI0lATZmxGyvBg&usqp=CAE",
    title: "Office Monitors",
  },
  {
    id: "7f21a04c-8a6f-4325-9b67-1abbe5c92d0a",
    imageUrl: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcS6KlHcArbzW0MUVkeMIRfgTKuedChqc7Ga1iquvQE-1uNCP2oLJRvTl0vPsIYk3-HE0JCWD108xg5gghJye4eVsGEZxcJxlc0iKPCi4QtW&usqp=CAE",
    title: "Smartwatches",
  },
  {
    id: "4adef2aa-f3be-42d9-86f7-af141c4833a2",
    imageUrl: "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSBXpLJskFlHS1SFUI2WDZ3lSbKBKykeJGTbXH5iQ1hPhfHuCWXn3UCu1E56ahSTwdonttcWcaWsS9dSwJSOv-rjNJMn7ywvoTlWl6pqySSF3UK11CC_kEh&usqp=CAE",
    title: "Robot Vacuum",
  },
  {
    id: "543453ce-cb21-486f-a674-b0d7cad250ce",
    imageUrl: "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcT4MZorJs_bWyfAU32CJtzOELfHxp8Lz4IP7yWy721_tuAuyCzJUfMcLcsydEXsi0Iofwpy9x4KXLkRVvYAi2UuzIbnFVJuu5Z60e382gJua8nIe6zEL_4vzw&usqp=CAE",
    title: "Airfryers"
  },
  {
    id: "952bade0-8f91-4dc8-8cde-ae48c5fbc3ad",
    imageUrl: "https://m.media-amazon.com/images/I/61Q9t+ksgNL._AC_SX679_.jpg",
    title: "Espresso Machines"
  },
  {
    id: "8984c706-b559-430a-9c7f-83876245a996",
    imageUrl: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcR82ZAUa41mF2a5zXljk8B-KzDcgTSeDzIxFW3Yp0yuHZg6ugOP46Ww4LUiWSQCghlzUtXWNuQlbow8nvR_HCYMDVLiCcLySBKg5fzSOMft6o9RerLssGtHfw&usqp=CAE",
    title: "Travel Mugs"
  },
  // {
  //   id: "ce43a5ee-4290-42ea-ba6a-da798b1844f7",
  //   imageUrl: "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRt92-ngFf2E0wA3J_Eu2Xz4KY21Ah3p93CK7dSfxI5SIVlLoiJbR9gvlA3Ha8uUsesxPSYtw4cz8BGKvhVxleRon5sH5eSsaoPUoAzOOo&usqp=CAE",
  //   title: "Moisturizers"
  // },
  {
    id: "5eeec037-1d4a-4d0b-b803-965bd99b9000",
    imageUrl: "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSH5F5C07egDxgf0zWgM_kMppj81ZHt7eWaqz3sFYY2GfbEI0Dy5AsyIJIsaDB9oa1HOCjLP2rwg4WBCGS8ltffZfxcsh30azRk69POPAKVooDvTgssIVceMQ&usqp=CAE",
    title: "Tennis Shoes"
  },
  {
    id: "79bbd957-4274-41c0-bb7a-dce643cfb07e",
    imageUrl: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcR4RyUZVztW76P6PLHRuqSZT92Oz0R8OVGL2cwYf_U4y3t4y30pM-SoiOuXJkT7fOoWROi4I50&usqp=CAE",
    title: "Work Backpacks"
  },
  {
    id: "9ebfcd1c-25c0-4074-a52d-1aec2632dc5a",
    imageUrl: "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSmmqEVwTApt7jdx3vPl-KHpHNhAFuV1TsVn8El7zMseAtwn5BwGR8RsLx471jlPtgknLD1rgnOfb7YTWoCE9-va2ZIHkCDriNuM3SmIAor&usqp=CAE",
    title: "Padel Racquets"
  },
  {
    id: "5302c9a6-0208-475e-880a-cb01879566a3",
    imageUrl: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcQ0pVDCTkvlNDOPD3tU3jr9KBdm9JwrXw3z3vHLdu9y_-yQ0PrAHvTm361XJIWIul8cRYjJJIYitOGAteEwcboZyb1d6aDEwXA0ugQYPdE&usqp=CAE",
    title: "Biographies"
  },
  // {
  //   id: "ce43a5ee-4290-42ea-ba6a-da798b1844f11",
  //   imageUrl: "https://m.media-amazon.com/images/I/41PLv7KMxJL._SY445_SX342_.jpg",
  //   title: "Startup Books"
  // }
];