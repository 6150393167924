import { Link } from "@nextui-org/link";
import { useEffect, useState } from "react";
import { SummaryOfSummaries, YoutubeProductReview } from "../WizefindClient";
import ProductSummaryProsConsQuotes from "./VideoSummaryProsConsQuotes";
import VideoSummaryProsConsQuotes from "./VideoSummaryProsConsQuotes";
import DisplayContent from "./DisplayContent";

const VideoSummaryContainer: React.FC<{ youtubeProductReviews: YoutubeProductReview[], youtubeReviewsSummary: SummaryOfSummaries | null, index?: number }> = ({ youtubeProductReviews, youtubeReviewsSummary, index = null }) => {

    const [selectedReviewIndex, setSelectedReviewIndex] = useState<number | null>(index);

    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-4 overflow-x-auto p-2">
                {youtubeProductReviews.map((review, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedReviewIndex(selectedReviewIndex === index ? null : index)}
                        className={`relative p-1 rounded-lg transition-all ${selectedReviewIndex === index
                            ? 'ring-2 ring-blue-500 scale-105'
                            : 'hover:ring-2 hover:ring-blue-300'
                            }`}
                    >
                        <img
                            className="max-w-[100px] rounded"
                            src={review.video.thumbnail?.static}
                            alt={review.video.title}
                        />
                        {selectedReviewIndex === index && (
                            <div className="absolute inset-0 bg-blue-500 opacity-20 rounded" />
                        )}
                    </button>
                ))}
            </div>
            <p className="text-gray-400 text-xs ml-1">Tip: select any video for individual reviews!</p>
            {/* Display selected review content */}
            {selectedReviewIndex !== null && (
                <div className="p-3 bg-gray-50 border rounded-lg">
                    <h2 className="text-lg font-bold">Video Review</h2>
                    <div className="flex flex-row gap-2 pb-2">
                        <img
                            className="max-w-[100px] py-2"
                            src={youtubeProductReviews[selectedReviewIndex].video.thumbnail?.static}
                            alt={youtubeProductReviews[selectedReviewIndex].video.title}
                        />
                        <Link
                            href={youtubeProductReviews[selectedReviewIndex].video.link}
                        >
                            {youtubeProductReviews[selectedReviewIndex].video.title}
                        </Link>
                    </div>

                    <ProductSummaryProsConsQuotes
                        youtubeProductReview={youtubeProductReviews[selectedReviewIndex]}
                        showPros={true}
                        showCons={true}
                        showQuotes={true}
                    />
                </div>
            )}
            {youtubeReviewsSummary && selectedReviewIndex === null && (
                <div className="bg-gray-50 border p-3">
                    <h2 className="text-xl font-bold mb-1">Summary of all Video Reviews</h2>

                    <VideoSummaryProsConsQuotes
                        youtubeProductReview={youtubeReviewsSummary}
                        showQuotes={true}
                    />
                </div>
            )}

        </div>
    );
};

export default VideoSummaryContainer;