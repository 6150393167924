import { Skeleton } from "@nextui-org/skeleton";
import { ReactNode, ReactElement } from 'react';

interface DisplaySkeletonProps {
    when: boolean;
    children: ReactNode;
    skeletonComponent?: ReactElement;
}

const DisplaySkeleton = ({ when, children, skeletonComponent }: DisplaySkeletonProps): ReactElement => {
    if (when) {
        return skeletonComponent || (
            <div className="max-w-6xl mx-auto">
                <Skeleton className="rounded-lg">
                    <div className="h-[20rem]">Loading...</div>
                </Skeleton>
            </div>
        );
    }

    return <>{children}</>;
};

export default DisplaySkeleton; 