import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DisplayContent from "./DisplayContent";

const ProductComparisonTable = ({ comparisonTable }: { comparisonTable: string | null }) => {
  return (
    <div className="p-3">

      <DisplayContent when={comparisonTable !== null}>
        <div className="bg-black p-2 text-white text-center font-extrabold">
          <h2 className="text-3xl">Comparison Table</h2>
        </div>
      </DisplayContent>

      <ReactMarkdown
        className="my-4"
        remarkPlugins={[remarkGfm]}
        components={{
          table: ({ node, ...props }) => (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 table-fixed" {...props} />
            </div>
          ),
          thead: ({ node, ...props }) => (
            <thead className="border bg-gray-200" {...props} />
          ),
          th: ({ node, ...props }) => (
            <th
              className="p-2 text-left text-base"
              {...props}
            />
          ),
          tr: ({ node, ...props }) => (
            <tr className="odd:bg-gray-100 even:bg-white" {...props} />
          ),
          td: ({ node, ...props }) => (
            <td className="p-2 text-sm whitespace-normal truncate" {...props} />
          ),
          ul: ({ node, ...props }) => (
            <ul className="list-disc list" {...props} />
          ),
        }}
      >
        {comparisonTable}
      </ReactMarkdown>
    </div>
  )
}


export default ProductComparisonTable;