import { Button } from '@nextui-org/button';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="max-w-6xl border-b px-6 py-3 flex items-center mx-auto relative">
      <div className="w-full flex flex-row gap-4 items-center ">
        <a href="/">
          <span className="text-base sm:text-3xl font-semibold">
            wizefind
          </span>
        </a>
        {location.pathname !== '/' && (
          <Button
            color="primary"
            radius="none"
            className="ml-auto"
            onClick={() => navigate('/')}
          >
            New Search
          </Button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;