import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Navbar from "../components/Navbar";
import { productCategoryCardData } from '../productCategoryCardData';

import { Link } from "@nextui-org/link";
import remarkGfm from "remark-gfm";
import DisplayContent from "../components/DisplayContent";
import MoreDiscoveredProductsGallery from "../components/MoreDiscoveredProductsGallery";
import ProductCategoryCard from "../components/ProductCategoryCard";
import ProductComparisonTable from "../components/ProductComparisonTable";
import ReviewedProductHeader from "../components/ReviewedProductHeader";
import VideoSummaryContainer from "../components/VideoSummaryContainer";
import VideoSummaryProsConsQuotes from "../components/VideoSummaryProsConsQuotes";
import { getTotalMinutes } from "../utils";
import { getExportedState, Product, SearchResultVideo } from "../WizefindClient";

export enum SearchType {
    COMPARE_PRODUCTS,
    FIND_BEST_PRODUCT,
    FIND_ALTERNATIVES,
}

export interface VideoData {
    video_id: string;
    video_title: string;
    length: string;
    video_thumbnail: string;
    transcript: string;
    link: string;
}

const CategoryDisplayPage = () => {
    const [discoveredProducts, setDiscoveredProducts] = useState<Product[]>([])
    const [discoveredProductVideos, setDiscoveredProductVideos] = useState<SearchResultVideo[]>([])
    const [reviewedProductsWithSummaries, setReviewedProductsWithSummaries] = useState<Product[]>([])
    const [comparisonTable, setComparisonTable] = useState<string | null>(null)
    const [conclusion, setConclusion] = useState<string | null>(null)

    const [categoryImage, setCategoryImage] = useState<string>("");
    const [categoryName, setCategoryName] = useState<string>("");
    const [exportId, setExportId] = useState<string | null>(null);

    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);


    const fetchInitialState = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const exportId = urlParams.get('exportId');
            if (!exportId) {
                return;
            }
            setExportId(exportId);
            const categoryInfos = productCategoryCardData.find(item => item.id === exportId);
            setCategoryImage(categoryInfos?.imageUrl || "");
            setCategoryName(categoryInfos?.title || "");

            const response = await getExportedState(exportId);

            const data = await response;
            setSelectedProducts(data.selectedProducts || []);
            setDiscoveredProductVideos(data.discoveredProductVideos || []);
            setDiscoveredProducts(data.discoveredProducts || []);
            setReviewedProductsWithSummaries(data.reviewedProductsWithSummaries || []);
            setComparisonTable(data.comparisonTable || null);
            setConclusion(data.conclusion || null);
        } catch (error) {
            console.error("Error fetching initial state:", error);
        }
    };

    useEffect(() => {
        fetchInitialState();
    }, []);

    return (
        <div>
            <Navbar></Navbar>

            <div className="relative w-full max-w-6xl p-6 mx-auto">
                <div className="absolute top-1/2 left-0 right-0 bottom-0 bg-gray-100 z-[-1]" />
                <div className="relative z-0">
                    <ProductCategoryCard
                        id={exportId || ""}
                        title={categoryName}
                        w={200}
                        h={200}
                        useHoverEffects={false}
                    />
                </div>
            </div>

            <div>
                {/* space */}
                {discoveredProducts.length > 0 && (
                    <>
                        <h2 className="text-2xl text-center font-extrabold pt-6">most recommended</h2>
                        <p className="text-center text-gray-600 text-sm pb-6 font-mono">
                            based on <span className="font-bold text-lg">{discoveredProductVideos.length}</span> videos and <span className="font-bold text-lg">{discoveredProductVideos ? getTotalMinutes(discoveredProductVideos) : 0}</span> minutes
                        </p>
                    </>
                )}

                <div className="flex flex-col sm:flex-row max-w-6xl mx-auto justify-between">
                    {selectedProducts.map((product: Product, index: number) => (
                        <Card
                            key={index}
                            radius="none"
                            shadow="none"
                        >
                            <CardHeader className="h-[200px]">
                                {product.google_shopping_data?.thumbnail ? (
                                    <img
                                        src={product.google_shopping_data?.thumbnail || ""}
                                        className="max-w-[200px] max-h-[200px] w-auto h-auto object-contain mx-auto"
                                    />
                                ) : (
                                    <div className="bg-gray-50 w-40 h-40 mx-auto" />
                                )}
                            </CardHeader>
                            <CardBody
                                className="text-center"
                            >
                                <p className="font-semibold">{product.name}</p>
                                <p className="text-gray-500 font-mono">{product.google_shopping_data?.price ? product.google_shopping_data.price.split('.')[0] : '?'}</p>  {/* <p className="text-gray-500 font-mono">{product.rating}</p> */}
                            </CardBody>
                            <CardFooter
                                className="flex flex-col mt-0 pt-0"
                            >
                                <div className="mt-2 text-sm">
                                    <p className="text-gray-600 text-center pb-3">Recommended in <span className="font-semibold text-lg">{product.mentioned_in_youtube_videos?.length}</span> videos</p>
                                    <div className="flex flex-col">
                                        {product.mentioned_in_youtube_videos?.map(
                                            (reference: SearchResultVideo, index: number) => (
                                                <div
                                                    className="flex flex-row gap-1 items-top px-1"
                                                    key={index}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0px"
                                                        y="0px"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <path
                                                            // fill="#cc6666"
                                                            // fill="#ffb3b3"
                                                            fill="rgba(255, 0, 0, 0.1)"
                                                            d="M2.771,27.348c-0.507-3.402-0.287-6.486,0.411-9.854c0.231-1.115,0.536-2.227,1.075-3.232 c1.761-3.28,5.685-4.823,9.4-5.24c3.715-0.416,7.283-0.221,11.015-0.437c5.51-0.319,10.337,0.289,15.482,1.48 c0.795,0.184,1.606,0.375,2.297,0.807c1.592,0.996,2.172,3.002,2.485,4.847c0.944,5.56,0.612,11.258-0.034,16.86 c-0.169,1.462-0.396,3.008-1.345,4.137c-0.848,1.008-2.146,1.522-3.422,1.867c-3.363,0.909-6.898,0.904-10.384,0.893 c-4.226-0.014-8.453-0.027-12.679-0.041c-4.114-0.013-8.593-0.152-11.757-2.766c-0.681-0.562-1.288-1.243-1.615-2.061 c-0.238-0.594-0.319-1.236-0.399-1.87C3.043,30.684,3.076,29.396,2.771,27.348z"
                                                        ></path>
                                                        <path
                                                            fill="#010101"
                                                            d="M46.014,22.201c-0.043-2.331-0.227-4.68-0.66-6.974c-0.32-1.695-0.914-3.51-2.355-4.582 c-0.828-0.616-1.837-0.861-2.826-1.088c-1.117-0.257-2.239-0.491-3.367-0.693c-2.19-0.393-4.403-0.67-6.625-0.791 c-2.37-0.129-4.715,0.005-7.082,0.083c-2.385,0.078-4.773,0.044-7.156,0.178C12.206,8.545,8.202,9.239,5.4,11.945 c-1.462,1.412-2.226,3.233-2.653,5.193c-0.493,2.258-0.786,4.579-0.75,6.892c0.018,1.157,0.127,2.306,0.292,3.451 c0.309,2.133,0.248,4.331,0.692,6.444c0.41,1.951,1.987,3.338,3.682,4.242c3.576,1.908,7.845,1.759,11.778,1.772 c4.917,0.016,9.85,0.142,14.766,0.013c2.371-0.062,4.766-0.263,7.063-0.887c1.88-0.511,3.625-1.413,4.428-3.285 c0.382-0.89,0.537-1.846,0.657-2.801c0.148-1.173,0.266-2.352,0.369-3.53C45.936,27.04,46.059,24.62,46.014,22.201z M44.402,32.579 c-0.235,2.017-0.695,3.903-2.647,4.888c-1.855,0.936-4.13,1.202-6.173,1.365c-4.485,0.358-9.042,0.134-13.537,0.119 c-2.184-0.007-4.37,0.012-6.554-0.032c-1.836-0.037-3.685-0.145-5.477-0.573c-1.788-0.427-3.56-1.193-4.874-2.512 c-1.49-1.496-1.431-3.717-1.579-5.693c-0.073-0.979-0.168-1.954-0.308-2.926c-0.319-2.207-0.333-4.443-0.074-6.658 c0.244-2.092,0.565-4.432,1.642-6.278c1.748-2.998,5.377-4.328,8.657-4.736c2.197-0.274,4.422-0.298,6.633-0.329 c2.202-0.031,4.398-0.168,6.599-0.206c4.259-0.074,8.481,0.461,12.633,1.384c0.959,0.213,2.013,0.378,2.857,0.911 c0.754,0.476,1.265,1.246,1.599,2.058c0.744,1.809,0.912,3.9,1.069,5.828C45.23,23.651,44.92,28.143,44.402,32.579z"
                                                        ></path>
                                                        <path
                                                            fill="#d6e5e5"
                                                            d="M19.6,17.534C19,22,19.78,26.353,19.7,30.466c3.819-2.139,7.937-4.278,11.757-6.417 c-1.568-0.967-3.336-1.955-4.998-2.75C24.247,20.242,21.461,18.791,19.6,17.534z"
                                                        ></path>
                                                        <path
                                                            fill="#010101"
                                                            d="M31.709,23.617c-3.848-2.363-8.1-4-11.857-6.515c-0.154-0.103-0.302-0.098-0.426-0.041 c-0.147,0.036-0.272,0.138-0.308,0.339c-0.431,2.392-0.563,4.791-0.357,7.213c0.166,1.952,0.469,3.887,0.439,5.851 c-0.006,0.386,0.427,0.614,0.752,0.432c3.897-2.179,7.86-4.237,11.757-6.417C32.044,24.293,32.022,23.81,31.709,23.617z M19.775,24.774c-0.192-2.15-0.119-4.281,0.206-6.408c3.349,2.13,7.034,3.667,10.461,5.667c-3.407,1.889-6.852,3.71-10.259,5.599 C20.137,28.007,19.919,26.396,19.775,24.774z"
                                                        ></path>
                                                    </svg>
                                                    <Link
                                                        className="text-xs"
                                                        size="sm"
                                                        href={reference.link ?? undefined}
                                                        isExternal
                                                        onClick={(e) => e.stopPropagation()} // Prevent triggering the parent onClick
                                                    >
                                                        {reference.title.length > 25
                                                            ? `${reference.title.slice(0, 25)}...`
                                                            : reference.title}
                                                    </Link>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    ))}
                </div>

                <div className="py-6"></div>
                <DisplayContent when={discoveredProducts.length > 0}>
                    <MoreDiscoveredProductsGallery
                        discoveredProducts={discoveredProducts}
                        selectedProducts={selectedProducts}
                    />
                </DisplayContent>
            </div>

            {/* space */}
            <div className="py-6"></div>

            <div className="max-w-6xl mx-auto">
                {reviewedProductsWithSummaries.map((product: Product, index: number) => (
                    <div key={index}>
                        <div className="py-6"></div>
                        <ReviewedProductHeader product={product} />
                        <div className="py-2"></div>
                        <VideoSummaryContainer youtubeProductReviews={product.youtube_reviews || []} youtubeReviewsSummary={null} index={0} />
                    </div>
                ))}
            </div>

            {reviewedProductsWithSummaries.length > 0 && (
                <div className="max-w-6xl mx-auto mt-12">
                    <div className="bg-black text-white text-center">
                        <h2 className="text-2xl py-2">Summarized Pros & Cons based on all videos</h2>
                    </div>
                    <div className="flex flex-col gap-4 mt-4">
                        {reviewedProductsWithSummaries.map((product: Product, productIndex: number) => (
                            product.youtube_reviews_summary && (
                                <div className="bg-gray-50 border p-3" key={productIndex}>
                                    <div className="flex flex-row align-center content-center gap-4 pb-2">
                                        <img
                                            className="max-w-[50px] object-cover"
                                            src={product.google_shopping_data?.thumbnail || ""}
                                        />
                                        <h2 className="text-xl font-bold font-mono py-2">{product.name}</h2>
                                    </div>
                                    <VideoSummaryProsConsQuotes
                                        key={productIndex}
                                        youtubeProductReview={product.youtube_reviews_summary}
                                        showQuotes={false}
                                    />
                                </div>
                            )
                        ))}
                    </div>
                </div>
            )}

            <div className="max-w-6xl mx-auto mt-8">
                <ProductComparisonTable
                    comparisonTable={comparisonTable}
                />
            </div>

            {/* space */}
            <div className="py-6"></div>

            {/* Conclusion */}
            {conclusion && (
                <div
                    className={`max-w-3xl px-3 mx-auto transition-opacity duration-1000 ease-in-out ${conclusion ? "opacity-100" : "opacity-0" // TODO: use invisible / hidden instead of opacity
                        }`}
                >
                    <div className="p-2 bg-black text-white rounded-md mb-4">
                        <h1 className="text-3xl font-extrabold text-center">Who should buy what?</h1>
                    </div>

                    <div className="prose max-w-full leading-5">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                ul: ({ node, ...props }) => <ul className="list-disc list" {...props} />,
                            }}
                        >
                            {conclusion}
                        </ReactMarkdown>
                    </div>
                </div>
            )}

            <div className="py-6"></div>

        </div>
    );
};

export default CategoryDisplayPage;
