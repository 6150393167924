import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { SummaryOfSummaries, YoutubeProductReview } from '../WizefindClient';

interface ProductSummaryProsConsQuotesProps {
    youtubeProductReview: YoutubeProductReview | SummaryOfSummaries;
    showPros?: boolean;
    showCons?: boolean;
    showQuotes?: boolean;
}

const ProductSummaryProsConsQuotes: React.FC<ProductSummaryProsConsQuotesProps> = ({
    youtubeProductReview,
    showPros = true,
    showCons = true,
    showQuotes = true
}) => {
    return (
        <div>
            {showPros && youtubeProductReview.pros && (
                <>
                    <h2 className="font-bold underline decoration-4 decoration-green-500/50">
                        Pros
                    </h2>
                    <ReactMarkdown
                        className="prose-sm"
                        remarkPlugins={[remarkGfm]}
                        components={{
                            ul: ({ node, ...props }) => (
                                <ul className="list-disc list" {...props} />
                            ),
                        }}
                    >
                        {youtubeProductReview.pros}
                    </ReactMarkdown>
                </>
            )}

            {showCons && youtubeProductReview.cons && (
                <>
                    <div className="py-3"></div>
                    <h2 className="font-bold underline decoration-4 decoration-red-500/50">
                        Cons
                    </h2>
                    <ReactMarkdown
                        className="prose-sm"
                        remarkPlugins={[remarkGfm]}
                        components={{
                            ul: ({ node, ...props }) => (
                                <ul className="list-disc list" {...props} />
                            ),
                        }}
                    >
                        {youtubeProductReview.cons}
                    </ReactMarkdown>
                </>
            )}

            {showQuotes && youtubeProductReview.quotes && (
                <>
                    <div className="py-3"></div>
                    <h2 className="font-bold underline decoration-4 decoration-gray-500/50">
                        Quotes
                    </h2>
                    <ReactMarkdown
                        className="prose-sm"
                        remarkPlugins={[remarkGfm]}
                        components={{
                            blockquote: ({ node, ...props }) => (
                                <blockquote
                                    className="text-gray-800 italic ml-1 my-3 pl-2 border-l-4"
                                    {...props}
                                />
                            ),
                        }}
                    >
                        {youtubeProductReview.quotes}
                    </ReactMarkdown>
                </>
            )}
        </div>
    );
};

export default ProductSummaryProsConsQuotes;