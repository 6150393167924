import React from 'react';
import { Product } from '../WizefindClient';
import { getTotalMinutes } from '../utils';


const ReviewedProductHeader: React.FC<{ product: Product }> = ({ product }) => {
    return (
        <>
            <div>
                {product.google_shopping_data?.thumbnail ? (
                    <img
                        className="max-w-[120px] sm:max-w-[120px] object-cover rounded-lg hover:scale-105 mx-auto"
                    src={product.google_shopping_data?.thumbnail || ''}
                        alt={`${product.name} thumbnail`}
                    />
                ) : (
                    <div className="bg-gray-50 w-20 h-20 mx-auto" />
                )}
                <h2 className="my-2 text-2xl font-bold text-center">{product.name}</h2>
                <p className="text-center text-gray-600 font-mono">
                    Summary of {' '}
                    <span className="font-bold text-lg">
                        {product.youtube_reviews?.length}
                    </span>{' '}
                    reviews and{' '}
                    <span className="font-bold text-lg">
                        {product.youtube_reviews
                            ? getTotalMinutes(product.youtube_reviews.map(review => review.video))
                            : 0}
                    </span>{' '}
                    minutes of content
                </p>
            </div>

            {/* <div className="mt-6 flex flex-row flex-wrap gap-1 justify-center">
                {product.youtube_reviews?.map((review, index) => (
                    <div key={index}>
                        <a
                            href={review.video.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="max-w-[80px] sm:max-w-[120px] object-cover rounded-lg hover:scale-105"
                                src={review.video.thumbnail?.static || ""}
                                alt={`Review video thumbnail ${index + 1}`}
                            />
                        </a>
                    </div>
                ))}
            </div> */}
        </>
    );
};

export default ReviewedProductHeader;