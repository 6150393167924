import { SearchResultVideo, YoutubeProductReview } from "./WizefindClient";

export const parseVideoLength = (length: string) => {
    const [seconds = 0, minutes = 0, hours = 0] = length.split(':').map(Number).reverse();
    const totalMinutes = Math.round(hours * 60 + minutes + seconds / 60);
    return totalMinutes === 0 ? '1 min' : `${totalMinutes} min`;
};

export const parseVideoLengthIntoNumber = (length: string) => {
    const [seconds = 0, minutes = 0, hours = 0] = length.split(':').map(Number).reverse();
    const totalMinutes = Math.round(hours * 60 + minutes + seconds / 60);
    return totalMinutes;
};

export function getTotalMinutes(videos: SearchResultVideo[]): number {
    let totalMinutes = 0;
    for (const video of videos) {
        const lengthStr = video.length;
        if (lengthStr) {
            const minutes = parseVideoLengthIntoNumber(lengthStr);
            totalMinutes += minutes;
        }
    }
    return totalMinutes;
}