import { Skeleton } from "@nextui-org/skeleton";

const VideoGallerySkeleton = () => {
    return (
        <div className="flex space-x-4 p-3 min-w-max mx-auto max-w-3xl">
            {[...Array(6)].map((_, index) => (
                <div key={index} className="flex flex-col w-[150px] h-[175px] rounded-lg bg-gray-50 overflow-hidden shadow-md">
                    <Skeleton className="w-full h-[75px] rounded-t-lg" />
                    <div className="p-2 flex-grow">
                        <Skeleton className="w-full h-3 mb-2 rounded-lg" />
                        <Skeleton className="w-2/3 h-3 rounded-lg" />
                    </div>
                    <div className="py-1"></div>
                    <div className="p-2 mt-auto">
                        <div className="flex justify-between items-center">
                            <Skeleton className="w-1/3 h-3 rounded-lg" />
                            <Skeleton className="w-1/4 h-3 rounded-lg" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VideoGallerySkeleton;