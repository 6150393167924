import React, { useState, PropsWithChildren } from 'react';
import { Copy as CopyIcon, Check as CheckIcon } from 'lucide-react';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Snippet, useDisclosure } from "@nextui-org/react";

interface ShareContentModalProps extends PropsWithChildren {
  isOpen: boolean;
  onOpenChange: () => void;
  exportUrl: string | null;
  setExportUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

const ShareContentModal: React.FC<ShareContentModalProps> = ({
  isOpen,
  onOpenChange,
  exportUrl,
  setExportUrl,
  children
}) => {
  const [copiedShareLinkIcon, setCopiedShareLinkIcon] = useState<React.ElementType>(CopyIcon);
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy link");

  const handleModalOpenChange = (open: boolean): void => {
    onOpenChange();
    if (!open) {
      setCopiedShareLinkIcon(CopyIcon);
      setExportUrl(null);
    }
  };

  const handleCopyClick = (): void => {
    if (exportUrl) {
      navigator.clipboard.writeText(exportUrl);
      setCopyButtonText("Copied!");
      setTimeout(() => {
        setCopyButtonText("Copy link");
      }, 2000);
    }
  };

  const CopiedShareLinkIcon = copiedShareLinkIcon;

  return (
    <Modal
      placement="center"
      backdrop="opaque"
      isOpen={isOpen}
      size="md"
      onOpenChange={handleModalOpenChange}
      classNames={{
        backdrop: "bg-black/50 !important",
      }}
    >
      <ModalContent className="max-h-[400px]">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              🔗 Copy the link to share
            </ModalHeader>
            <ModalBody>
              <Snippet
                variant="bordered"
                hideSymbol
                classNames={{
                  pre: "whitespace-pre-wrap break-all"
                }}
                copyIcon={<CopiedShareLinkIcon size={20} />}
                onCopy={() => {
                  setCopiedShareLinkIcon(CheckIcon);
                }}
              >
                {exportUrl ? exportUrl : "Loading..."}
              </Snippet>
              {children}
            </ModalBody>
            <ModalFooter>
              <Button 
                color="danger" 
                variant="light" 
                onPress={onClose}
              >
                Close
              </Button>
              <Button 
                color="primary" 
                variant="flat"
                onPress={handleCopyClick}
              >
                {copyButtonText}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ShareContentModal;