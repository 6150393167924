import React from 'react';
import { useNavigate } from 'react-router-dom';
import { productCategoryCardData } from '../productCategoryCardData';
import { CATEGORY_DISPLAY_PAGE_PATH } from '../App';
interface ProductCategoryCardProps {
  id: string;
  title: string;
  w: number;
  h: number;
  useHoverEffects?: boolean;
}

const ProductCategoryCard: React.FC<ProductCategoryCardProps> = ({
  id,
  title,
  w,
  h,
  useHoverEffects = true,
}) => {
  const navigate = useNavigate();
  ;

  return (
    <div className="flex flex-col items-center">
      <div>
        <img
          src={productCategoryCardData.find(card => card.id === id)?.imageUrl}
          alt={title}
          onClick={() => navigate(`${CATEGORY_DISPLAY_PAGE_PATH}?exportId=${id}`)}
          className={`p-6 bg-white rounded-md h-[${h}px] w-[${w}px] object-fit transform ${
            useHoverEffects ? 'hover:cursor-pointer hover:scale-105' : ''
          }`}
        />
      </div>
      <p className="uppercase font-mono mt-4 text-xl font-bold">{title}</p>
      {/* <p className="text-gray-500 text-sm">most recommended</p> */}
    </div>
  );
};

export default ProductCategoryCard;
