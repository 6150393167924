import React from 'react';

import { Button } from '@nextui-org/button';
import { DISCOVERY_PAGE_PATH } from "../App";

interface ShareContentButtonProps {
    isLoading: boolean;
    onOpen: () => void;
    currentState: () => unknown;
    exportState: (state: unknown) => Promise<string>;
    setExportUrl: (url: string) => void;
}

const ShareContentButton: React.FC<ShareContentButtonProps> = ({
    isLoading,
    onOpen,
    currentState,
    exportState,
    setExportUrl
}) => {
    const handleShare = async (): Promise<void> => {
        const exportId = await exportState(currentState());
        setExportUrl(`${window.location.origin}${DISCOVERY_PAGE_PATH}?exportId=${exportId}`);
    };

    return (
        <Button
            className="text-md fixed bottom-4 right-4 z-50"
            size="sm"
            disabled={isLoading}
            onPress={onOpen}
            onClick={handleShare}
        >
            Share
        </Button>
    );
};

export default ShareContentButton;