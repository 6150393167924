import { Input, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";


import { Button } from "@nextui-org/button";
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ProductComparisonTable from "../components/ProductComparisonTable";
import { getTotalMinutes } from "../utils";
import { CancellationError, discoverProducts, discoverProductVideos, exportState, extractProducts, generateConclusion, generateProductComparisonTable, generateSummaryOfSummaries, getExportedState, getVideoTranscripts, getYoutubeVideos, Product, reviewProducts, SearchResultVideo, searchVideos } from "../WizefindClient";

import ConclusionSection from "../components/ConclusionSection";
import ConclusionSectionSkeleton from "../components/ConclusionSectionSkeleton";
import DiscoveredProductsGallery from "../components/DiscoveredProductsGallery";
import DisplayContent from "../components/DisplayContent";
import DisplaySkeleton from "../components/DisplaySkeleton";
import ProductComparisonTableSkeleton from "../components/ProductComparisonTableSkeleton";
import ReviewedProductHeader from "../components/ReviewedProductHeader";
import ShareContentButton from "../components/ShareContentButton";
import ShareContentModal from "../components/ShareContentModal";
import VideoGallery from "../components/VideoGallery";
import VideoGallerySkeleton from "../components/VideoGallerySkeleton";
import VideoSummaryContainer from "../components/VideoSummaryContainer";
import VideoSummaryProsConsQuotes from "../components/VideoSummaryProsConsQuotes";
import { data } from "../data";

export enum SearchType {
    COMPARE_PRODUCTS,
    FIND_BEST_PRODUCT,
    FIND_ALTERNATIVES,
}

export interface VideoData {
    video_id: string;
    video_title: string;
    length: string;
    video_thumbnail: string;
    transcript: string;
    link: string;
}


const DiscoveryPage = () => {
    const API_URL =
        process.env.REACT_APP_BACKEND_API_URL || "http://localhost:8000";

    const [youtubeVideos, setYoutubeVideos] = useState<SearchResultVideo[]>([])
    const [discoveredProducts, setDiscoveredProducts] = useState<Product[]>([])
    const [discoveredProductVideos, setDiscoveredProductVideos] = useState<SearchResultVideo[]>([])
    const [videos, setVideos] = useState<SearchResultVideo[]>([])
    const [reviewedProducts, setReviewedProducts] = useState<Product[]>([])
    const [reviewedProductsWithSummaries, setReviewedProductsWithSummaries] = useState<Product[]>([])
    const [comparisonTable, setComparisonTable] = useState<string | null>(null)
    const [conclusion, setConclusion] = useState<string | null>(null)

    const [userQuery, setUserQuery] = useState<string>("")
    const [searchType, setSearchType] = useState(SearchType.FIND_BEST_PRODUCT);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [inputValue, setInputValue] = useState<string>("")

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingYoutubeVideos, setIsLoadingYoutubeVideos] = useState(false)
    const [isLoadingVideos, setIsLoadingVideos] = useState(false)
    const [isLoadingDiscoveredProducts, setIsLoadingDiscoveredProducts] = useState(false)
    const [isLoadingReviewedProducts, setIsLoadingReviewedProducts] = useState(false)
    const [isLoadingReviewedProductsWithSummaries, setIsLoadingReviewedProductsWithSummaries] = useState(false)
    const [isLoadingComparisonTable, setIsLoadingComparisonTable] = useState(false)
    const [isLoadingConclusion, setIsLoadingConclusion] = useState(false)
    const [isLoadingDiscoveredProductVideos, setIsLoadingDiscoveredProductVideos] = useState(false)
    const [requestId, setRequestId] = useState<string | null>(null);

    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [exportUrl, setExportUrl] = useState<string | null>(null);
    const [exportID, setExportID] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const location = useLocation();


    const cancelRequest = async (requestId: string) => {
        try {
            const response = await fetch(`${API_URL}/api/cancel-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: JSON.stringify({ request_id: requestId }),
            });
            if (!response.ok) {
                throw new Error("Error when calling /cancel-request endpoint");
            }
        } catch (error) {
            console.error("Error cancelling request:", error);
        }
    }

    const handleExportedState = async (data: any) => {
        setSelectedProducts(data.selectedProducts || []);
        setDiscoveredProductVideos(data.discoveredProductVideos || []);
        setDiscoveredProducts(data.discoveredProducts || []);
        setReviewedProducts(data.reviewedProducts || []);
        setReviewedProductsWithSummaries(data.reviewedProductsWithSummaries || []);
        setComparisonTable(data.comparisonTable || null);
        setConclusion(data.conclusion || null);
    }

    const setDummyData = (data: any) => {
        setDiscoveredProducts(data.discoveredProducts);
        setDiscoveredProductVideos(data.discoveredProductVideos);
        setVideos(data.videos);
        setReviewedProducts(data.reviewedProducts);
        setReviewedProductsWithSummaries(data.reviewedProductsWithSummaries);
        setComparisonTable(data.comparisonTable);
        setConclusion(data.conclusion);
    }

    const handleUrlParams = async () => {
        const urlParams = new URLSearchParams(location.search);
        const query = urlParams.get('query');
        const exportId = urlParams.get('exportId');
        if (exportId) {
            setExportID(exportId)
            const response = await getExportedState(exportId);
            const data = await response;
            await handleExportedState(data);
        } else if (query) {
            if (query === "abc") {
                setDummyData(data);
            } else {
                setSelectedProducts([]);
                setSearchType(SearchType.FIND_BEST_PRODUCT);
                setUserQuery(query);
                handleButtonClick(query);
            }
        }
    };

    useEffect(() => {
        handleUrlParams();
    }, [location.search]);

    const resetState = (fullReset: boolean = false) => {
        setErrorMessage(null);
        setVideos([]);
        setReviewedProducts([]);
        setReviewedProductsWithSummaries([]);
        setComparisonTable(null);
        setConclusion(null);

        if (searchType !== SearchType.FIND_BEST_PRODUCT || fullReset) {
            setDiscoveredProducts([])
            setDiscoveredProductVideos([])
            setSelectedProducts([]);
        }

        setIsLoadingVideos(false)
        setIsLoadingYoutubeVideos(false)
        setIsLoadingReviewedProducts(false)
        setIsLoadingComparisonTable(false)
        setIsLoadingConclusion(false)
        setIsLoadingDiscoveredProducts(false)
        setIsLoadingDiscoveredProductVideos(false)
    }

    const searchYoutubeVideos = async () => {
        resetState()
        setIsLoadingYoutubeVideos(true)
        const videos = await searchVideos(inputValue, uuidv4())
        setYoutubeVideos(videos);
        setIsLoadingYoutubeVideos(false)
    }

    const compareProducts = async (userQuery: string) => {
        try {
            if (requestId) {
                resetState(true)
                setIsLoading(true)
                await cancelRequest(requestId) // Don't await. Just start new request. The old one will be cancelled on the background.
            }

            resetState()
            setIsLoading(true);

            setIsLoadingVideos(true)

            let products: Product[];
            if (searchType === SearchType.COMPARE_PRODUCTS) {
                products = await withRequestId((requestId) => extractProducts(userQuery, requestId));
            } else {
                products = selectedProducts;
            }

            const allVideos: SearchResultVideo[] = [];
            for (const product of products) {
                const videos = await withRequestId((requestId) => getYoutubeVideos(product, requestId));
                allVideos.push(...videos);
                setVideos((prevVideos) => [...prevVideos, ...videos]);
            }

            // Adds videos that were used to discover the to-be-compared products
            // (only relevant if the user previously used the 'product discovery' user-flow)
            if (selectedProducts.length > 0) {
                for (const product of selectedProducts) {
                    const referenceVideos = product.mentioned_in_youtube_videos;
                    if (referenceVideos && referenceVideos.length > 0) {
                        allVideos.push(...referenceVideos);
                        setVideos((prevVideos) => [...prevVideos, ...referenceVideos]);
                    }
                }
            }

            // Removes video duplicates from the list of to-be-analyzed videos
            const uniqueVideos = allVideos.filter((video, index, self) =>
                index === self.findIndex(v => v.id === video.id)
            );


            setIsLoadingVideos(false)
            setIsLoadingReviewedProducts(true)
            const reviewedProducts: Product[] = await withRequestId((requestId) => reviewProducts(products, uniqueVideos, requestId));
            setReviewedProducts(reviewedProducts);
            setIsLoadingReviewedProducts(false);

            // setIsLoadingComparisonTable(true) // todo: loading logic for skeleton for summary of summaries
            setIsLoadingReviewedProductsWithSummaries(true)
            const reviewedProductsWithSummaries: Product[] = await withRequestId((requestId) => generateSummaryOfSummaries(reviewedProducts, requestId));
            setReviewedProductsWithSummaries(reviewedProductsWithSummaries);
            setIsLoadingReviewedProductsWithSummaries(false)
            // setIsLoadingComparisonTable(false) // todo: loading logic for skeleton for summary of summaries

            setIsLoadingComparisonTable(true)
            const productComparisonTable: string = await withRequestId((requestId) => generateProductComparisonTable(reviewedProducts, requestId));
            setComparisonTable(productComparisonTable);
            setIsLoadingComparisonTable(false)

            setIsLoadingConclusion(true)
            const conclusion: string = await withRequestId((requestId) => generateConclusion(reviewedProducts, productComparisonTable, requestId));
            setConclusion(conclusion);
            setIsLoadingConclusion(false)
            setIsLoading(false)
        } catch (error) {
            if (!(error instanceof CancellationError)) {
                console.error("Error finding best product:", error);
                setIsLoading(false)
                resetState(true)
                setErrorMessage("An error occurred. Please try again.")
            } else {
                resetState(true)
                console.warn("Request cancelled")
            }
        }
    };


    const withRequestId = async <T,>(operation: (requestId: string) => Promise<T>): Promise<T> => {
        const newRequestId = uuidv4();
        setRequestId(newRequestId);
        try {
            return await operation(newRequestId);
        } finally {
            setRequestId(null);
        }
    };

    const findBestProduct = async (searchQuery: string) => {
        try {
            if (requestId) {
                resetState(true)
                setIsLoading(true)
                await cancelRequest(requestId) // Don't await. Just start new request. The old one will be cancelled on the background.
            }

            setIsLoading(true);
            resetState()

            setIsLoadingDiscoveredProductVideos(true)

            const videos = await withRequestId((requestId) => discoverProductVideos(searchQuery, requestId));
            if (videos.length === 0) {
                resetState(true)
                setErrorMessage("No videos found. Please try a different query.")
                setIsLoading(false)
                return;
            }

            setDiscoveredProductVideos(videos);
            setIsLoadingDiscoveredProductVideos(false)

            setIsLoadingDiscoveredProducts(true)
            const discoveredProducts: Product[] = await withRequestId((requestId) => discoverProducts(searchQuery, videos, requestId));
            setDiscoveredProducts(discoveredProducts);
            setIsLoadingDiscoveredProducts(false)
            setIsLoading(false);
        } catch (error) {
            if (!(error instanceof CancellationError)) {
                console.error("Error finding best product:", error);
                setIsLoading(false)
                resetState(true)
                setErrorMessage("An error occurred. Please try again.")
            } else {
                console.warn("Request cancelled")
                resetState(true)
            }
        }
    };

    const handleButtonClick = async (customSearchInput: string | null = null) => {
        const queryToUse =
            customSearchInput !== null ? customSearchInput : userQuery;

        if (queryToUse === "") {
            return;
        }
        setSelectedProducts([])
        setDiscoveredProducts([])
        findBestProduct(queryToUse);
    };

    // const handleProductButtonClick = (selectedProduct: Product) => {
    const handleProductButtonClick = (selectedProduct: Product) => {
        setSelectedProducts((listOfDiscoveredProducts) => {
            if (listOfDiscoveredProducts.some(p => p.name === selectedProduct.name)) {
                return listOfDiscoveredProducts.filter(p => p.name !== selectedProduct.name);
            } else if (listOfDiscoveredProducts.length < 3) {
                return [...listOfDiscoveredProducts, selectedProduct];
            } else {
                return listOfDiscoveredProducts;
            }
        });
    };

    const currentState = () => {
        const stateToExport = {
            discoveredProducts,
            discoveredProductVideos,
            videos,
            reviewedProducts,
            reviewedProductsWithSummaries,
            comparisonTable,
            conclusion,
            userQuery,
            searchType,
            selectedProducts,
        };
        return stateToExport
    }

    const extractProductsFromVideos = async () => {
        try {
            setIsLoadingDiscoveredProducts(true)
            const videosWithTranscripts = await getVideoTranscripts(youtubeVideos, uuidv4())
            const discoveredProducts = await discoverProducts(inputValue, videosWithTranscripts, uuidv4());
            setDiscoveredProducts(discoveredProducts);
            setIsLoadingDiscoveredProducts(false)
        } catch (error) {
            console.error("Error extracting products from videos:", error);
        }
    }


    return (
        <div className="max-w-8xl mx-auto">
            <Navbar></Navbar>

            <DisplayContent when={userQuery === "" && exportID === null}>
                <div className="flex max-w-3xl pt-4 mx-auto">
                    <Input
                        variant="bordered"
                        size="lg"
                        type="text"
                        radius="none"
                        errorMessage={errorMessage}
                        isInvalid={errorMessage !== null}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="e.g. Best wireless earpods"
                        onKeyDown={(e) => e.key === "Enter" && searchYoutubeVideos()}
                    />
                    <Button
                        className="bg-primary text-white"
                        radius="none"
                        size="lg"
                        isLoading={isLoadingYoutubeVideos}
                        onClick={() => searchYoutubeVideos()}
                    >
                        Search Videos
                    </Button>
                </div>

                <div className="py-3"></div>

                <DisplaySkeleton when={isLoadingYoutubeVideos} skeletonComponent={
                    <VideoGallerySkeleton />
                }>
                    <VideoGallery videos={youtubeVideos} />
                </DisplaySkeleton>

                <DisplayContent when={youtubeVideos.length > 0}>
                    <div className="flex max-w-3xl pt-4 mx-auto justify-center">
                        <Button
                            className="bg-primary text-white"
                            radius="none"
                            size="lg"
                            isLoading={isLoadingDiscoveredProducts}
                            onClick={() => extractProductsFromVideos()}
                        >
                            Extract Products
                        </Button>
                    </div>
                </DisplayContent>
            </DisplayContent>

            <ShareContentButton
                isLoading={isLoading}
                onOpen={onOpen}
                currentState={currentState}
                exportState={exportState}
                setExportUrl={setExportUrl}
            ></ShareContentButton>

            <ShareContentModal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                exportUrl={exportUrl}
                setExportUrl={setExportUrl}
            >
            </ShareContentModal>


            <div className="py-2 sm:py-4"></div>
            <h2 className="text-3xl text-center font-extrabold py-4">
                {userQuery}
            </h2>

            <DisplaySkeleton when={isLoadingDiscoveredProductVideos}>
                <DisplayContent when={discoveredProductVideos.length > 0}>
                    <p className="text-center text-gray-600 pb-4 font-mono font-semibold">
                        based on <span className="font-bold text-lg">{discoveredProductVideos.length}</span> videos and <span className="font-bold text-lg">{discoveredProductVideos ? getTotalMinutes(discoveredProductVideos) : 0}</span> minutes of content
                    </p>
                    <div className="max-w-6xl mx-auto flex flex-row flex-wrap gap-2 justify-center mb-12">
                        {discoveredProductVideos.map((video: SearchResultVideo, index: number) => (
                            <div key={index} className="">
                                <a
                                    href={video.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="max-w-[100px] sm:max-w-[120px] object-cover hover:scale-105"
                                        src={video.thumbnail?.static || ""}
                                    />
                                    <p className="p-1 text-center text-tiny text-gray-500">{video.length} min</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </DisplayContent>
            </DisplaySkeleton>

            <DisplaySkeleton when={isLoadingDiscoveredProducts}>
                <DisplayContent when={discoveredProducts.length > 0}>
                    <div className="max-w-6xl mx-auto">
                        <DiscoveredProductsGallery
                            discoveredProducts={discoveredProducts}
                            onProductButtonClick={handleProductButtonClick}
                            selectedProducts={selectedProducts}
                        />
                    </div>
                </DisplayContent>
            </DisplaySkeleton>


            <DisplayContent when={selectedProducts.length > 0}>
                <div className="max-w-6xl mx-auto text-center pt-4">
                    <Button
                        className=""
                        color="primary"
                        size="lg"
                        isLoading={isLoading}
                        isDisabled={selectedProducts.length == 0 || false}
                        onClick={() =>
                            compareProducts(
                                selectedProducts.map((product) => product.name).join(" vs ")
                            )
                        }
                    >
                        Compare {selectedProducts.length} selected products
                    </Button>
                </div>
            </DisplayContent>

            <DisplaySkeleton when={isLoadingVideos} skeletonComponent={
                <VideoGallerySkeleton />
            }>
                <VideoGallery
                    videos={videos}
                />
            </DisplaySkeleton>

            <div className="py-6"></div>

            <DisplaySkeleton when={isLoadingReviewedProducts || isLoadingReviewedProductsWithSummaries}>
                <div className="max-w-6xl mx-auto">
                    {reviewedProductsWithSummaries.map((product: Product, index: number) => (
                        <div key={index}>
                            <div className="py-6"></div>
                            <ReviewedProductHeader product={product} />
                            <div className="py-2"></div>
                            <VideoSummaryContainer youtubeProductReviews={product.youtube_reviews || []} youtubeReviewsSummary={product.youtube_reviews_summary} />
                        </div>
                    ))}
                </div>
            </DisplaySkeleton>

            <div className="py-6"></div>

            {/* <DisplaySkeleton when={isLoadingReviewedProductsWithSummaries}>
                <div className="max-w-6xl mx-auto flex flex-col gap-4">
                    {reviewedProductsWithSummaries.length > 0 && (
                        <div className="bg-black text-white text-center py-2">
                            <h2 className="text-lg font-bold">Summarized Pros & Cons based on all videos</h2>
                        </div>
                    )}

                    {reviewedProductsWithSummaries.map((product: Product, productIndex: number) => (
                        product.youtube_reviews_summary && (
                            <div className="bg-gray-50 border p-3">
                                <div className="flex flex-row align-center content-center gap-4 pb-2">
                                    <img
                                        className="max-w-[50px] object-cover"
                                        src={product.google_shopping_data?.thumbnail || ""}
                                    />
                                    <h2 className="text-xl font-bold font-mono py-2">{product.name}</h2>
                                </div>
                                <VideoSummaryProsConsQuotes
                                    key={productIndex}
                                    youtubeProductReview={product.youtube_reviews_summary}
                                    showQuotes={false}
                                />
                            </div>
                        )
                    ))}
                </div>
            </DisplaySkeleton> */}

            <DisplaySkeleton when={isLoadingComparisonTable}
                skeletonComponent={<ProductComparisonTableSkeleton />
                }>
                <ProductComparisonTable
                    comparisonTable={comparisonTable}
                />
            </DisplaySkeleton>

            {/* space */}
            <div className="py-6"></div>

            <DisplaySkeleton when={isLoadingConclusion} skeletonComponent={
                <ConclusionSectionSkeleton />
            }>
                <ConclusionSection conclusion={conclusion} />
            </DisplaySkeleton>

            <div className="py-6"></div>

        </div >
    );
};

export default DiscoveryPage;
