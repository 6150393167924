import { Link } from "@nextui-org/react";
import { useState } from "react";
import { parseVideoLength } from "../utils";
import { SearchResultVideo } from "../WizefindClient";
import DisplayContent from "./DisplayContent";


const VideoGallery = ({ videos }: { videos: SearchResultVideo[] }) => {
    const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(null);

    return (
        <div className="max-w-3xl w-full mx-auto">
            <DisplayContent when={videos.length > 0}>
                <div className="px-3 py-1">
                    <h2 className="font-bold text-gray-700 text-lg">
                        Sources:
                    </h2>
                </div>
            </DisplayContent>
            <div className="overflow-x-auto pb-4">
                <DisplayContent when={videos.length > 0}>
                    <div
                        className={`flex space-x-4 p-3 min-w-max transition-opacity duration-1000 ease-in-out ${videos.length > 0 ? "visible" : "invisible"}`}
                    >
                        {videos.map((video: SearchResultVideo, index: number) => (
                            <div
                                key={index}
                                onClick={() =>
                                    setSelectedVideoIndex(
                                        index === selectedVideoIndex ? null : index
                                    )
                                }
                                className="flex flex-col max-w-[150px] h-[175px] rounded-md bg-gray-50 overflow-hidden shadow-md cursor-pointer"
                            >
                                <img
                                    className="w-full object-cover h-[75px]"
                                    src={video.thumbnail?.static}
                                    alt={`Thumbnail for ${video.title}`}
                                />
                                <div className="p-1">
                                    <p className="text-gray-700 font-semibold text-xs">{video.title}</p>
                                </div>
                                <div className="p-1 mt-auto">
                                    <div className="flex justify-between items-center">
                                        <Link
                                            className="text-xs"
                                            size="sm"
                                            href={video.link}
                                            isExternal
                                            showAnchorIcon
                                            style={{ pointerEvents: "auto", userSelect: "none" }}
                                            onClick={(e) => e.stopPropagation()} // Prevent triggering the parent onClick
                                        >
                                            YouTube
                                        </Link>
                                        <p className="text-xs text-gray-500">
                                            {parseVideoLength(video.length ?? "")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </DisplayContent>
            </div>
        </div>
    )
};

export default VideoGallery;