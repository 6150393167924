import React, { useRef } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/react";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Product } from '../WizefindClient';


interface MoreDiscoveredProductsGalleryProps {
  discoveredProducts: Product[];
  selectedProducts: Product[];
}

const MoreDiscoveredProductsGallery: React.FC<MoreDiscoveredProductsGalleryProps> = ({ discoveredProducts, selectedProducts }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: string) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 400; // Adjust this value to control scroll distance
      const newScrollPosition = scrollContainerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-3 bg-gray-100 relative pb-12">
      <h2 className="text-xl text-center font-semibold text-gray-700 pb-4">
        other recommended
      </h2>

      {/* Left Arrow */}
      <button
        onClick={() => scroll('left')}
        className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-r shadow-md z-10"
        aria-label="Scroll left"
      >
        <ChevronLeft className="w-6 h-6 text-gray-700" />
      </button>

      {/* Product Gallery */}
      <div
        ref={scrollContainerRef}
        className="flex flex-row gap-3 overflow-x-auto bg-gray-100 scroll-smooth scrollbar-hide mx-2 md:mx-10"
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        {discoveredProducts
          .filter((p) => !selectedProducts.some(sp => sp.name === p.name))
          .slice(0, 8)
          .map((product, index) => (
            <Card
              key={index}
              className="min-w-[200px] flex-shrink-0 bg-white"
            >
              <CardHeader>
                <p className="text-center mx-auto text-tiny text-gray-500">Recommended in <span className="text-blue-500">{product.mentioned_in_youtube_videos?.length}</span> videos</p>
              </CardHeader>
              <CardBody>
                {product.google_shopping_data?.thumbnail ? (
                  <img
                    src={product.google_shopping_data?.thumbnail || ""}
                    alt={product.name}
                    className="max-w-[100px] max-h-[100px] w-auto h-auto object-contain mx-auto"
                  />
                ) : (
                  <div className="bg-gray-50 w-20 h-20 mx-auto" />
                )}
              </CardBody>
              <CardFooter className="flex flex-col">
                <p className="font-semibold text-tiny">{product.name}</p>
                <p className="text-gray-500 text-tiny font-mono">
                  {product.google_shopping_data?.price
                    ? product.google_shopping_data.price.split('.')[0]
                    : '?'}
                </p>
              </CardFooter>
            </Card>
          ))}
      </div>

      {/* Right Arrow */}
      <button
        onClick={() => scroll('right')}
        className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-l shadow-md z-10"
        aria-label="Scroll right"
      >
        <ChevronRight className="w-6 h-6 text-gray-700" />
      </button>
    </div>
  );
};

export default MoreDiscoveredProductsGallery;