import { Skeleton } from "@nextui-org/skeleton";

const ConclusionSectionSkeleton = () => {
    return (
        <div className="max-w-3xl mx-auto">
            <Skeleton className="rounded-lg h-[20rem] max-w-3xl my-2">
                <div>Some content</div>
            </Skeleton>
            <Skeleton className="rounded-lg h-[20rem] max-w-3xl my-2">
                <div>Some content</div>
            </Skeleton>
        </div>
    );
};

export default ConclusionSectionSkeleton; 