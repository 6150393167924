import { Button } from "@nextui-org/react";
import { useState } from "react";
import { SearchType } from "../MainPage";
import { Input } from "@nextui-org/react";
import { Search } from "lucide-react";
import { DISCOVERY_PAGE_PATH } from "../App";
import { useNavigate } from "react-router-dom";


const PRODUCT_COMPARISON_EXAMPLES = [
    { name: "Apple Watch S9 vs Oura Ring 3", icon: "⌚️", },
    { name: "Dropbox vs Google Drive", icon: "💾", },
    { name: "CeraVe Hydrating Cleanser vs CeraVe Foaming", icon: "🧴", },
    { name: "Yonex Ezone vs Babolat Pure Drive", icon: "🎾", },
];

const FIND_PRODUCT_EXAMPLES = [
    { name: "Best Notetaking Apps for iPad", icon: "📝", },
    { name: "Best Smartwatch for Fitness", icon: "⌚️", },
    { name: "Best Moisturizer for Oily Skin", icon: "🧴", },
    { name: "Best Tennis Racquets for Beginners", icon: "🎾", },
];

const SearchBox = ({ isLoading, searchType, searchInput, setSearchInput, errorMessage, setErrorMessage }: { isLoading: boolean, searchType: SearchType, searchInput: string, setSearchInput: (searchInput: string) => void, errorMessage: string | null, setErrorMessage: (errorMessage: string | null) => void }) => {
    const navigate = useNavigate();

    const navigateToDiscoveryPage = (customSearchInput?: string) => {
        const params = new URLSearchParams({
            query: customSearchInput || searchInput
        });
        if (customSearchInput || searchInput) {
            navigate(`${DISCOVERY_PAGE_PATH}?${params.toString()}`);
        } else {
            setErrorMessage("Please enter a search query");
        }
    }


    return (

        <div id="search-box">
            {/* Button Group */}
            {/* <div className="flex justify-center">
                <div className="text-xs sm:text-sm inline-flex justify-center gap-2 bg-zinc-200 p-1 rounded-full">
                    <button
                        className={`py-2 px-4 font-semibold rounded-full transition-colors ${searchType === SearchType.FIND_BEST_PRODUCT
                            ? "bg-white text-black shadow"
                            : "text-gray-500 hover:bg-gray-300"
                            }`}
                        onClick={() => setSearchType(SearchType.FIND_BEST_PRODUCT)}
                    >
                        Discover Products
                    </button>
                    <button
                        className={`py-2 px-3 font-semibold rounded-full transition-colors ${searchType === SearchType.COMPARE_PRODUCTS
                            ? "bg-white text-black shadow"
                            : "text-gray-500 hover:bg-gray-300"
                            }`}
                        onClick={() => setSearchType(SearchType.COMPARE_PRODUCTS)}
                    >
                        Compare Products
                    </button>
                </div>
            </div> */}

            <div className="py-2"></div>

            {/* BEGIN OF SEARCH BOX */}
            <div className="rounded-none md:rounded-xl max-w-3xl p-2 mx-auto">
                {/* Search Box */}
                <div className="p-1">
                    {searchType === SearchType.COMPARE_PRODUCTS && (
                        <>
                            {/* Searchbar + Button */}
                            {/* <div className="flex w-full items-center space-x-1">
                                <Input
                                    variant="bordered"
                                    size="lg"
                                    type="text"
                                    radius="none"
                                    placeholder="Apple Watch S9 vs Oura Ring 3"
                                    value={searchInput}
                                    onChange={(e) => {
                                        setSearchInput(e.target.value);
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" && handleButtonClick()}
                                />
                                <Button
                                    className="bg-primary text-white"
                                    size="lg"
                                    radius="none"
                                    isLoading={isLoading}
                                    onClick={() => handleButtonClick()}
                                >
                                    Compare
                                </Button>
                            </div> */}

                            {/* <div className="p-5 sm:py-6"></div>
                            <div className="text-gray-500">
                                <p className="font-semibold text-sm pb-1">
                                    Try some examples
                                </p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 w-full">
                                    {PRODUCT_COMPARISON_EXAMPLES.map((product, index) => (
                                        <Button
                                            key={index}
                                            size="sm"
                                            className="bg-gray-100 justify-start text-wrap sm:text-sm"
                                            onClick={() => {
                                                setSearchInput(product.name);
                                                handleButtonClick(product.name);
                                            }}
                                        >
                                            {product.icon} {product.name}
                                        </Button>
                                    ))}
                                </div>
                            </div> */}
                        </>
                    )}

                    {searchType === SearchType.FIND_BEST_PRODUCT && (
                        <>
                            <div className="flex w-full items-start">
                                <Input
                                    variant="bordered"
                                    size="lg"
                                    type="text"
                                    radius="none"
                                    value={searchInput}
                                    errorMessage={errorMessage}
                                    isInvalid={errorMessage !== null}
                                    onChange={(e) => {
                                        setSearchInput(e.target.value);
                                    }}
                                    placeholder="e.g. Best wireless earpods"
                                    onKeyDown={(e) => e.key === "Enter" && navigateToDiscoveryPage()}
                                    />
                                <Button
                                    className="bg-primary text-white"
                                    radius="none"
                                    size="lg"
                                    isLoading={isLoading}
                                    onClick={() => navigateToDiscoveryPage()}
                                >
                                    Search
                                </Button>
                            </div>
                            <p className="text-gray-400 text-xs mt-1 ml-1">Tip: use simple keywords or YouTube-like queries!</p>
                            {/* <p className="ml-2 text-danger-300 text-xs">For more personalized results, try to be specific like "<span className="underline">best tennis racquets for BEGINNERS</span>"</p> */}
                            {/* <div className="p-5 sm:py-6"></div>

                            <div className="text-gray-500">
                                <p className="font-semibold text-sm pb-1">
                                    Try some examples
                                </p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 w-full">
                                    {FIND_PRODUCT_EXAMPLES.map((product, index) => (
                                        <Button
                                            key={index}
                                            size="sm"
                                            className="bg-gray-100 justify-start text-wrap sm:text-sm"
                                            onClick={() => {
                                                setSearchInput(product.name);
                                                navigateToDiscoveryPage(product.name)
                                            }}
                                        >
                                            {product.icon} {product.name}
                                        </Button>
                                    ))}
                                </div>
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </div>


    )
}

export default SearchBox;